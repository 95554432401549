import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import Home from '../src/Components/Home/Home';
// import ClinicLogin from '../src/Components/Auth/ClinicLogin';
// import PatientLogin from '../src/Components/Auth/PatientLogin';
// import ClinicSignUp from '../src/Components/Auth/ClinicSignUp';
// import PatientSignUp from '../src/Components/Auth/PatientSignUp';
// import Dashboard from '../src/Components/Dashboard/Dashboard';
// import Profile from '../src/Components/Dashboard/Profile';  
// import Patients from '../src/Components/Dashboard/Patients';  
// import Reporting from '../src/Components/Dashboard/Reporting';  
// import Billing from '../src/Components/Dashboard/Billing';
// import Authenticate from '../src/Components/Dashboard/Authenticate';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/login" element={<PatientLogin />} />
        <Route path="/sign-up" element={<PatientSignUp />} />
        <Route path="/clinic-login" element={<ClinicLogin />} />
        <Route path="/clinic-sign-up" element={<ClinicSignUp />} /> */}

        {/* Dashboard Route with Nested Routes */}
        {/* <Route path="/dashboard" element={<Dashboard />}> */}
        {/* Redirect to Patients when /dashboard is accessed */}
        {/* <Route index element={<Navigate to="/dashboard/patients" replace />} /> */}

        {/* Define all nested routes for dashboard */}
        {/* <Route path="profile" element={<Profile />} />
          <Route path="patients" element={<Patients />} />
          <Route path="reporting" element={<Reporting />} />
          <Route path="billing" element={<Billing />} />
          <Route path="authenticate" element={<Authenticate />} />
        </Route> */}
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();